<template>
  <div class="card">
    <div class="card-header">
      User Options
    </div>
    <div class="card-body">
      <FormInput
        v-model="form.password"
        label="Actual Password"
        type-password
      />
      <FormInput
        v-model="form.new_password"
        label="New Password"
        type-password
      />
      <FormInput
        v-model="form.new_password_confirmation"
        label="New Password Confirmation"
        type-password
      />
      <div
        class="btn btn-success btn-sm btn-block mt-3"
        @click="sendForm"
      >
        <i class="fas fa-save mr-3" />
        Save
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { FormInput } from '@/components/inputs';

export default {
  components: {
    FormInput,
  },
  data: () => ({
    form: {},
  }),
  methods: {
    ...mapActions([
      'updateProfile',
      'logout',
    ]),
    sendForm() {
      this.updateProfile(this.form)
        .then(() => {
          this.$noty.success('Profile updated successfully');
          this.logout().then(() => this.$router.push({ name: 'Login' }));
        })
        .catch(this.showError);
    },
  },
};
</script>

<style>

</style>
